import { Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, Injectable, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { ScrollService } from 'src/app/shared/services/Scroll/scroll.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { DOCUMENT } from '@angular/common';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { error } from 'jquery';
import { add } from 'date-fns';
import { AddressCompany, Categorias, Company, Open, typeInterface } from 'src/app/Interfaces/company';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { Cliente } from 'src/app/Interfaces/customer';
import { PixelService } from 'src/app/shared/services/pixel.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
@Injectable({
  providedIn: 'root'
})

export class MenuComponent implements OnInit, AfterViewInit{

  //#region Variaveis de Elementos HTML
    @ViewChildren('sections') sections!: QueryList<ElementRef>;
    @ViewChild('sections', { read: ElementRef }) sectionsRefs: ElementRef[] = [];
    @ViewChild('input', {static: false }) input!: ElementRef;
  //#endregion

  //#region Variaveis de estilo
    verifiScrolling = false;
    backgroundLoad = false;
    collapseBox = false;
    alterationBg = false;
    alterationpadding = false;
    buttonClicked = false;
    showSideBar = true;
    emptyProduct = false;
    showInput!: boolean;
    firstClick!: boolean;
    openModalTwo = false;
    requestSuccess = false;
    alterationIcon!: boolean;
    readOnlyState: boolean = false;
    darkTheme = this.cacheService.getItemLocal('dark-theme')
    toggleTheme = this.darkTheme;
  //#endregion

  //#region Variaveis locais
    //Privadas
    private verificationRequest = false;
    typeParam!: typeInterface;
    private scrollTopPosition: number = 0;
    private positions!: ElementRef[];

    statusCompany = false;
    nameCompany: string = '';
    nameEmpresa!: string;
    imgLogo!: string;
    imgBackground!: string;
    verification: boolean = false;
    nameStatusCompany!: string;
    filter: string = '';
    counterCart: number = 0;
    tabela!: Categorias[];
    company!: Company;
    tabelaFiltrada!: Categorias[]
    userConfirmation = this.cacheService.getItemLocal('userConfirmation');
    ex!: Categorias[]
    timeOpen: string = '';
    timeClose: string = '';
    addressUrl: string = '';
    user!: Cliente;
    fidelity!: Fidelity;

    imgsLoadead!: boolean[];
  //#endregion

  //#region Inicializadores
    constructor(
      private router: Router,
      private changeDetectorRef: ChangeDetectorRef,
      private cacheService: CacheService,
      private scrollService: ScrollService,
      private authLayout: AuthLayoutComponent,
      private cartService: CartService,
      private connection: ConnectionService,
      private renderer: Renderer2,
      private sharedService: SharedService,
      private eventService: EventsService,
      private apiService: ApiService,
      @Inject(DOCUMENT) private document: Document,
      private pixelService: PixelService,
      private titleService: Title,
      private metaService: Meta
      )
      {
        this.cartService.counter$.subscribe(newCount => {
          this.counterCart = newCount;
        });
        this.connection.nameCompany$.subscribe((newNameCompany: string) => {
          this.nameCompany = newNameCompany;
        });
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam
          if(this.typeParam.type == 0 || this.typeParam.type == 3){
            this.verification = false;
          }
          else{
            this.verification = true
          }

          if(this.company){
            this.validationReadOnly()
          }
        })
        this.connection.verificationRequest$.subscribe((newValue) =>{
          this.verificationRequest = newValue
        })
        this.scrollService.scrolling$.subscribe((scrolling) =>{
          this.verifiScrolling = scrolling;
        })
        this.connection.fidelity$.subscribe(
          (data)=>{
            this.fidelity = data
          }
        )
        this.connection.user$.subscribe((User) =>{
          this.user = User;
          // if(!this.fidelity){
          //   this.getFidelity();
          // }
        })

        this.sharedService.filter$.subscribe((newValue) =>{
          this.filter = newValue
          if(this.filter != ''){
            this.showInput = true;
          }
        })
        this.sharedService.valueShared$.subscribe((newValue) =>{
          this.tabelaFiltrada = newValue
        })
    }

    ngOnInit() 
    {
      if(!this.user){
        this.connection.getUser();
      }

      if(this.cacheService.getItemLocal('user_key')){
        this.userConfirmation = true;
      }

      //Recebe todas as informações da loja
      this.getStore();

      //Altera a estrutura do layout
      this.authLayout.checkScreenSize();

      //Verifica se é mesa ou comanda
      this.tableOrCard();

      //Exibe ou esconde a barra lateral
      this.toggleSideBar();

      this.cartService.getCartItems();

      if(this.verificationRequest)
      {
        this.backgroundLoad = true;
      }
    }

    ngAfterViewInit(){
      //Resgata as posições das categorias
      this.resgataPosition();

      //Altera o padding de acordo com o tamanho da tela
      this.alterationPadding();

      this.alterationBG()

      
    }

    ngOnDestroy(): void {
      this.eventService.eventFilter$.subscribe(data=>{
        if(!data)
        {
          this.sharedService.valueSharedSubject.next(this.ex);
          this.sharedService.filterSubject.next('');
        }
        else{
          this.sharedService.valueSharedSubject.next(this.tabelaFiltrada)
          this.sharedService.filterSubject.next(this.filter)
        }
      })
    }


    updateMetaTags() {
      this.titleService.setTitle(`${this.company.company.companyName} - Pedeaqui`);
      
      this.metaService.addTags([
        { property: 'og:title', content: `${this.company.company.companyName} - Pedeaqui`},
      ]);
    }

  //#endregion

  //#region Funções de estilo (Style)
    timeVerification(){
      this.timeOpen = this.company.company.today.open.substring(0, 5);
      this.timeClose = this.company.company.today.close.substring(0, 5);
    }

    //Exibe ou esconde a barra lateral
    toggleSideBar(){
      if(window.innerWidth < 1169){
        this.showSideBar = false
      }
      else {
        this.showSideBar = true;
      }
    }

    //Altera o icone caso a seja mesa ou comanda
    tableOrCard(){
      if(this.typeParam.type != 0)
      {
        if(this.typeParam.type == 1)
        {
          this.alterationIcon = false;
        }
        else if(this.typeParam.type == 2)
        {
          this.alterationIcon = true;
        }
      }
    }

    //Deasabilitao scroll da pagina
    disableScroll(){
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
    }

    //Abilita o scroll da pagina
    enableScroll(){
      this.renderer.removeStyle(this.document.body, 'overflow');
    }

    //adiciona classe dark-theme para mudar o tema
    addclass() {
      this.authLayout.addclass();
      this.toggleTheme = !this.toggleTheme;
      this.cacheService.setItemLocal('dark-theme', this.toggleTheme)
    }

    //resgata a posição das sections, para que a side-bar possa usar
    resgataPosition(){
      this.positions = [];
      this.sections.forEach((element: ElementRef) =>{
        this.positions.push(element)
      });
      this.scrollService.loadSections(this.positions);
    }

    //Altera o padding para dar o espaçamento certo
    alterationPadding(){
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if(window.innerWidth <= 846)
      {
        if(this.scrollTopPosition >= 140)
        {
          this.alterationpadding = true
        }
        else {
          this.alterationpadding = false
        }

        this.changeDetectorRef.detectChanges();

      }
    }

    //Altera qual menu superior vai usar
    alterationBG(){
      if(window.innerWidth <= 846)
      {
        this.alterationBg = false
      }
      else{
        this.alterationBg = true
      }
      this.changeDetectorRef.detectChanges();
    }

    //Função que é chamada quando clicado o botão de lupa
    expanderInput(){
      this.showInput = true
        setTimeout(() => {
          if (this.input && this.input.nativeElement) {
            this.input.nativeElement.focus();
          }
        }, 200);
    }

    //Função que é chamada assim que o Focus sai do elemento Input
    isElementFocused(){
      this.showInput = false
    }

    //Chamada para limpar o filtro
    isFilterEmpty(): boolean {
      return this.filter === '';
    }

    //Fecha o input
    closeInput(){
      this.buttonClicked = false;
    }

    //Limpa o filtro
    clearFilter(){
      this.filter = '';
      this.tabelaFiltrada = this.tabela
      this.input.nativeElement.focus();
    }

    //Abre o modal de informações da empresa
    openModalCompany(){
      this.openModalTwo = true;
      this.disableScroll()
    }

    loadImgComplete(event: Event, index: number){
      const element = event.target as HTMLImageElement;

      this.imgsLoadead[index] = true;
      this.renderer.addClass(element, 'complete')

    }

    loadBackground(){
      if(!this.verificationRequest)
      {
        setTimeout(() => {
          this.backgroundLoad = true;
        }, 500);
      }
      else{
        this.backgroundLoad = true;
      }

    }
  
    openModalCompanyEvent(event: any)
    {
      this.openModalTwo = event;
    }

    validationReadOnly(){
      //-------------------------
      //TYPE INTERFACE
      // 0 = Delivery
      // 1 - Mesa
      // 2 - Comanda
      // 3 - Balcão
      //-----------------------
      if(this.company.configs.readOnly == 0){
        if(this.typeParam.type == 0){
          if(this.company.configs.deliveryReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
  
        }
        else if(this.typeParam.type == 1){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 2){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 3){
          if(this.company.configs.counterReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
      }
      else{
        this.readOnlyState = true;
      }
    }

  //#region Chamadas de eventos da tela
    @HostListener('window:resize', [])
    onResize(){
      this.alterationPadding();
      this.alterationBG();
      if(window.innerWidth < 1169){
        this.showSideBar = false
      }
      else {
        this.showSideBar = true;
      }

    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): boolean  {
      this.collapseBox = false

      this.alterationBG();
      this.alterationPadding();

      if (this.showInput == true) {
        if (this.firstClick == true) {
          if (window.pageYOffset === 0) {
            this.firstClick = false;
            this.expanderInput();
          }
        }
      }
      return false;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent): void {
      event.preventDefault();
      this.enableScroll();
    }

  //#endregion

  //#endregion

  //#region Funções de Lógica (Logic)

    //captura a id do elemento clicado e redireciona para rota do detalhe do produto
    capturaId(id: number, indexSection: number){
      if(this.nameCompany == '')
      {
        this.connection.nameCompany$.subscribe({
          next: (newNameCompany: string) => {
            console.log('Novo nome da empresa recebido:', newNameCompany);
          },
          error: (error) => {
            console.error('Erro ao receber nome da empresa:', error);
          },
          complete: () => {
            console.log('Completado');
          }
        });

        if(this.nameCompany == '')
        {
          this.nameCompany = this.cacheService.getItemLocal('name_cache_key')
        }
      }
      this.eventService.eventFilterSubject.next(true);
      // this.scrollService.sectionIndex = indexSection;
      this.router.navigate([this.nameCompany, 'product-detail', id])

    }

    //Primero request na API para resgatar todas informações
    getStore(){

      if(!this.verificationRequest)
      {
        this.connection.Empresa(this.nameCompany, this.typeParam.type).subscribe((data) =>{
          if(data)
          {
            //Carrega as variaveis com as informações da Loja
            this.connection.redirectSubject.next(false)
            this.cacheService.verificationInicialization = true;
            this.company = data.response;
            this.tabela = this.company.categories;
            this.imgLogo = this.company.company.logo;
            this.nameEmpresa = this.company.company.companyName.toUpperCase()
            this.imgBackground = this.company.company.background;
            this.timeVerification();
            this.updateMetaTags();
            // this.getFidelity()

            let value = 0
            this.tabela.forEach(category => {
              value += category.products.length
            });

            this.imgsLoadead = Array(value).fill(false);

            if(this.company){
              document.body.style.setProperty('--primary-color', this.company.configs.colorCompany);
              document.body.style.setProperty('--color-button-positive', this.company.configs.colorCompany);
              document.body.style.setProperty('--color-button-negative', this.company.configs.colorCompany);
            }


            //Status Loja
            this.verifiStatusCompany();

            setTimeout(() => {
              this.connection.StopLoadingSubject.next(true)
            }, 2500);

            setTimeout(() => {
              this.requestSuccess = true;
            }, 2000);

            this.connection.verificationRequestSubject.next(true);
            this.changeDetectorRef.detectChanges();
            this.resgataPosition();
            this.validationReadOnly();
            setTimeout(() => {
              this.sendEvent();
            }, 2000);

            this.pixelService.initializePixel(this.company.company.pixel)

        }
        },
        (error) =>{
          console.log('Empresa não encontrada, verifique se o nome da empresa esta correto na Url.')
          this.router.navigate(['/landing/home']);
        })
      }
      else
      {
        this.connection.company$.subscribe((Company) =>{
          if(Company)
          {
            //Carrega as variaveis com as informações da Loja
            this.connection.redirectSubject.next(false)
            this.cacheService.verificationInicialization = true;
            this.company = Company;
            this.tabela = this.company.categories;
            this.nameEmpresa = this.company.company.companyName.toUpperCase()
            this.imgLogo = this.company.company.logo;
            this.imgBackground = this.company.company.background;
            this.requestSuccess = true;
            this.timeVerification();
            this.updateMetaTags();
            
            // if(!this.fidelity){
            //   this.getFidelity();
            // }

            let value = 0
            this.tabela.forEach(category => {
              value += category.products.length
            });

            this.imgsLoadead = Array(value).fill(false);

            //Status Loja
            this.verifiStatusCompany();

            this.validationReadOnly();

            setTimeout(() => {
              this.sendEvent();
            }, 2000);
            
            this.pixelService.initializePixel(this.company.company.pixel)

          }
          this.resgataPosition();

        })
      }
    }

    //Verifica se a loja esta fechada ou aberta
    verifiStatusCompany(){
      if(this.company.company.status == 0)
      {
        this.nameStatusCompany = 'Loja Fechada';
        this.statusCompany = false;
        this.changeDetectorRef.detectChanges();
      }
      else if(this.company.company.status == 1)
      {
        this.nameStatusCompany = 'Loja Aberta';
        this.statusCompany = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    //Envia o evento de ViewContent para meta
    sendEvent(){
      this.pixelService.trackViewContent({
        action_source: 'website',
        event_id: Math.floor(Math.random() * 100000),
        event_time: Math.floor(Date.now() / 1000),
        user_data: {
          client_user_agent: navigator.userAgent,
        },
        eventref: this.pixelService.getEventRef() || '',
      })
    }

    //Função responsavel por fazer o filtro de pesquisa
    updatefilter(event: Event) {
      this.filter = (event?.target as HTMLInputElement).value.toLowerCase();

      this.tabelaFiltrada = this.tabela.map(category => {
            const filteredProducts = category.products.filter(product => {
            const productNameLowerCase = product.name.toLowerCase();
            const filterLowerCase = this.filter;

            const isProductIncluded = !filterLowerCase || productNameLowerCase.includes(filterLowerCase);

            // console.log(`Product: ${product.name}, Filter: ${this.filter}, Is Included: ${isProductIncluded}`);

            return isProductIncluded;
          });

          return { ...category, products: filteredProducts };
        })
        .filter(category => category.products.length > 0);


        if (this.tabelaFiltrada.length === 0 && this.filter !== '') {
          this.emptyProduct = true;
        }
        else{
          this.emptyProduct = false;
        }

        this.sharedService.valueSharedSubject.next(this.tabelaFiltrada);
        this.sharedService.filterSubject.next(this.filter);
    }

    getFidelity(){
      // if(this.company){
      //   if(this.user){
      //     this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
      //       (data) =>{
      //         this.connection.updateFidelity(data);
      //       },
      //       (error) =>{
      //         console.log(error);
      //       })
      //   }
      // }

    }

    //Cria um link de compartilhamento do menu
    share(): void {
      if (navigator.share) {
        navigator.share({
          title: `Acesse o cardapio de ${this.nameEmpresa}`,
          url: `https://pedeaqui.app/${this.nameCompany}`
        }).then(() => {
          console.log('Compartilhado com sucesso!');
        })
        .catch(console.error);
      } else {
        }
    }

  //#endregion

  }
