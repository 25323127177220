import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { ApiService } from '../../services/API/api.service';
import { ConnectionService } from '../../services/Connection/connection.service';
import { Coupon, CouponUser } from 'src/app/Interfaces/coupon';
import { OrderUser } from 'src/app/Interfaces/pedido';
import { SharedService } from '../../services/shared/shared.service';
import { Cliente } from 'src/app/Interfaces/customer';
import { DatePipe, DOCUMENT } from '@angular/common';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrl: './cupom.component.css'
})
export class CupomComponent implements OnInit, AfterViewInit, OnChanges{
  @Input() order!: OrderUser
  @Input() desvinculation: boolean = false
  @Output() coupon: EventEmitter<{ check: boolean, coupon: Coupon}> = new EventEmitter<{ check: boolean, coupon: Coupon}>();
  @Output() view: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  id_company!: number;
  dayOfWeek!: number;
  orderPrice: number = 0;
  cupomDigit: string = '';
  cupomEmpty!: Coupon;
  minValue = false;
  stringCoupomEmpty = false;
  nameCompany: string = '';
  openView: boolean = false;
  user!: Cliente;
  coupomNotValid!: Coupon | undefined;
  couponSelected!: Coupon;
  cupomselecionado = false;
  couponsUserValid: CouponUser[] = [];
  couponsUserInvalid: CouponUser[] = [];
  datesFormatted: string[] = [];
  viewSelected: number = 1;
  notCouponsUserValid: boolean = false;
  notCouponsUserInvalid: boolean = false;
  showTopMenu: boolean = false;
  alteration = false;
  labelNotApliqued: string = '';
  openInfoCoupon: boolean = false;
  openRuleCoupon: boolean = false;
  rulesCouponSelected!: CouponUser;

  constructor(
    private apiService: ApiService,
    private connection: ConnectionService,
    private sharedService: SharedService,
    private authLayout: AuthLayoutComponent,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
  ){
    this.connection.company$.subscribe((newCompany) =>{
      this.id_company = newCompany.company.id_company
    })

    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })

    this.sharedService.methodReceipt$.subscribe((data) =>{
      if(data == true){
        this.cupomselecionado = false;
        // this.couponSelected  = this.cupomEmpty
        this.coupon.emit({ check: false, coupon: this.cupomEmpty});
        this.cupomDigit = '';
        this.minValue = false;
      }
    })

    this.connection.user$.subscribe((data) =>{
      if(data){
        this.user = data;
      }
    })
    this.getDay();
  }

  ngOnInit(): void {
 
    if(this.order.deliveryFee && this.order.id_address){
      this.orderPrice = this.order.orderPrice - this.order.deliveryFee
    }
    else{
      this.orderPrice = this.order.orderPrice
    }

    this.filterCoupons()

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order']) {
      if(!changes['order'].firstChange)
        this.filterCoupons()
    }
    if(changes['desvinculation'])
    {
      if(changes['desvinculation'].currentValue){
        this.changeButton()
      }
    }
  }

  ngAfterViewInit(): void {
    this.checkScreenSize()
  }

  //Responsavel por verificar o cupom e retornar se ele é valido ou não
  aplicationCoupom(cupom: string, type: number) {
    if (!cupom) {
      this.stringCoupomEmpty = true;
      return;
    }
  
    this.apiService.VerifiCoupon(cupom, this.id_company, this.dayOfWeek, this.order.products, this.order.id_customerFK).subscribe(
      (data) => {
        //Cupom Inválido
        if (data.empty) {
          this.coupomNotValid = data;
          this.cupomselecionado = false;
          this.labelNotApliqued = 'Cupom inválido.';
          return;
        }
        
        //Cupom Expirado
        if (data.expired) {
          this.coupomNotValid = data;
          this.labelNotApliqued = 'Cupom expirado';
          this.cupomselecionado = false;
          return;
        }
        
        //Selecionar Endereço de Entrega
        if (data.deliveryFree && this.order.orderType === 5 && !this.order.id_address) {
          this.handleInvalidCoupon(type, data, 'Por favor, selecione o endereço de entrega primeiro.');
          return;
        }
        
        //Selecionar o tipo entrega
        if (data.deliveryFree && this.order.orderType !== 5) {
          this.handleInvalidCoupon(type, data, 'Este cupom é válido apenas para pedidos com entrega.');
          return;
        }
        
        //Valor minimo
        if (data.minValue && this.orderPrice < data.minValue) {
          this.coupomNotValid = data;
          this.cupomselecionado = false;
          this.minValue = true;
          return;
        }

        //Produtos
        if(data.couponType == 2){
          if(!this.validCouponProducts(data.discountProducts)){
            this.handleInvalidCoupon(type, data, 'Este cupom não é válido para o seu pedido.');
            return
          }
        }

        
        
        this.applyCoupon(data);
      },
      (error) => {
        console.log(error);
        this.alteration = !this.alteration;
      }
    );
  }
  
  //Responsavel por exibir mensagem de Invalido
  handleInvalidCoupon(type: number, data: any, message: string) {
    this.coupomNotValid = data;
    this.cupomselecionado = false;
    
    if (type === 1) {
      this.labelNotApliqued = message;
    } else {
      this.alteration = !this.alteration;
      this.toastr.clear();
      this.toastr.warning(message, 'Atenção', { positionClass: 'toast-top-right', timeOut: 4000 });
    }
  } 

  //Responsavel por aplicar o cupom.
  applyCoupon(data: any) {
    this.couponSelected = data;
    this.cupomselecionado = true;
    this.openView = false;
    this.view.emit(false);
    this.EmitEventCoupomSelected(this.couponSelected);
    
    if (this.couponSelected.couponType !== 2) {
      this.toastr.success('Cupom aplicado', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000 });
    }
  }

  //Resgata o dia exato da semana, puxando diretamente do servidor
  getDay(){
    this.apiService.getHours().subscribe(
      (data) =>{
        const date = new Date(data.date)
        this.dayOfWeek = date.getDay();
      },
      (error) =>{}
    )
  }

  //Quando o endereço é selecionado
  EmitEventCoupomSelected(cupom: Coupon) {
    this.coupon.emit({ check: true, coupon: cupom});
  }

  //Desabilita o scroll da pagina
  disableScroll(){
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
  }

  //Abilita o scroll da pagina
  enableScroll(){
    this.renderer.removeStyle(this.document.body, 'overflow');
  }

  //Quando o botão de alterar e acionado
  changeButton(){
    this.labelNotApliqued = '';
    this.cupomselecionado = false;
    this.coupon.emit({ check: false, coupon: this.cupomEmpty});
    this.cupomDigit = '';
    this.minValue = false;
    this.openView = true;
    this.view.emit(true);
    this.coupomNotValid = undefined;
  }
  
  //Limpa os campos do input ao usuario fazer uma novo clique nele
  checkChange(event: any){
    this.coupomNotValid = this.cupomEmpty;
    this.stringCoupomEmpty = false;
  }

  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }

//#region View

  //para  voltar para o order-confirmation
  goBack(){
    this.cupomDigit = '';
    this.coupomNotValid = undefined;
    this.stringCoupomEmpty = false;
    this.minValue = false;
    this.labelNotApliqued = '';
    this.openView = false;
    this.view.emit(false);
  }

  //Ao abrir a view de cupons
  viewOpen(){
    this.openView = true;
    this.view.emit(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: 'instant' as ScrollBehavior,
        top: 0,
        left: 0
      });
    }, 10);

  }

  //Acionado ao clicar no card
  changeCheckBox(event: any){
    const data = event
    if(data.checked){
      this.aplicationCoupom(data.value, 2);
    }
  }

  //Troca de view entre disponiveis e indisponiveis
  changeView(view: number){
    this.viewSelected = view
  }

  //Abre o canva de regras do cupon
  openRulesCoupon(coupon: CouponUser){
    this.openRuleCoupon = true;
    this.rulesCouponSelected = coupon;
    this.disableScroll()
  }

  //Valida os cupons do usuario
  filterCoupons(){
    this.couponsUserInvalid = [];
    this.couponsUserValid = [];
    if(this.user.coupons && this.user.coupons.length != 0){
      this.user.coupons.forEach(coupon =>{
        coupon.valid = {
          products: false,
          minValue: false,
          delivery: false
        }

        const transformedDate = this.datePipe.transform(coupon.couponExpires, 'dd/MM/yyyy HH:mm');

        if (transformedDate) {
          coupon.expiresFormatted = transformedDate;
        }

        if(!coupon.expired){


          if(coupon.couponType == 2){

            if(this.validCouponProducts(coupon.productsDiscount)){
              coupon.valid.products = true
            }
            else{
              coupon.valid.products = false;
            }
          }
          else{
            coupon.valid.products = true;
            
          }


          if(coupon.couponType == 3){
            if(this.order.orderType == 5 && this.order.id_address){
              coupon.valid.delivery = true
            }
          }
          else{
            coupon.valid.delivery = true;
          }
          
          if(this.order.orderType == 5 && this.order.id_address){
            if(Number(this.order.orderPrice - this.order.deliveryFee) >= Number(coupon.couponMinValue)){
              coupon.valid.minValue = true
            }
          }
          else{
            if(Number(this.order.orderPrice) >= Number(coupon.couponMinValue)){
              coupon.valid.minValue = true
            }
          }
          this.couponsUserValid.push(coupon)
        }
        else{
          this.couponsUserInvalid.push(coupon)
        }
      })

      this.calcDaysMissing();

      if(this.couponsUserValid.length > 0){
        this.notCouponsUserValid = false;
      }
      else{
        this.notCouponsUserValid = true;
      }
      if(this.couponsUserInvalid.length > 0){
        this.notCouponsUserInvalid = false;
      }
      else{
        this.notCouponsUserInvalid = true;
      }
    }
    else{
      this.notCouponsUserValid = true;
      this.notCouponsUserInvalid = true;
    }
  }

  //Calcula quantos dias falta para expirar o cupom
  calcDaysMissing(){
    this.apiService.getHours().subscribe(
      (data) =>{
        this.couponsUserValid.forEach(coupon => {
          const dataAlvo = new Date(coupon.couponExpires);

          const today = new Date(data.date)

          const diffTime = dataAlvo.getTime() - today.getTime();

          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          this.datesFormatted.push(diffDays.toString())
  
        });
      },
      (error) =>{}
    )
  }

  //Valida se os cupons de desconto em produto se aplica no carrinho do cliente
  validCouponProducts(productsDiscount: any[]){
    if(productsDiscount.length != 0){
      for (let i = 0; i <= productsDiscount.length; i++) {
        const valid = this.order.products.find(product => product.id_product == productsDiscount[i])
        if(valid){
          return true
        } 
      }
      return false
    }
    else{
      return false
    }

  }

//#endregion

}
