<app-top-menu-two *ngIf="showTopMenu"></app-top-menu-two>
<div style="height: 100vh; max-width: 800px; margin: auto;" [ngClass]="{'response': this.showTopMenu}">
  <div class="container">
    <div class="navegation-header d-flex align-items-center">
        <div class="box-icon" (click)="goBack()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
        <div class="box-title">
          <h3 style="margin-bottom: 0px;">Fidelidade</h3>
        </div>
    </div>
  </div>
  <div class="box-fidelity" *ngIf="this.fidelityGet">
    <div class="fidelity-option" *ngIf="this.fidelityUser.fidelityOption == 2">
      <div class="progress-bar-fidelity">
        <span>0</span>
        <div class="progress" style="width: 100%;" role="progressbar" aria-label="Animated striped example"  [attr.aria-valuenow]="this.fidelityUser.ordersValue" [attr.aria-valuemin]="0" [attr.aria-valuemax]="fidelityUser.fidelityValue">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="background-color:  var(--primary-color);" [style.width.%]="this.percentageValue"></div>
        </div>
        <span>{{ fidelityUser.fidelityCount }}</span>
      </div>
    </div>

    <div class="fidelity-option" *ngIf="this.fidelityUser.fidelityOption == 1">
      <div class="progress-span">
        <span>{{ this.fidelityUser.ordersCount }} de {{ this.fidelityUser.fidelityCount }} pedidos</span>
      </div>
      <div class="progress-bar-fidelity">
        <span>0</span>
        <div class="progress" style="width: 100%;" role="progressbar" aria-label="Animated striped example"  [attr.aria-valuenow]="this.fidelityUser.ordersCount" [attr.aria-valuemin]="0" [attr.aria-valuemax]="fidelityUser.fidelityCount">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="background-color:  var(--primary-color);" [style.width.%]="this.percentageCount"></div>
        </div>
        <span>{{ fidelityUser.fidelityCount }}</span>
      </div>
    </div>

    <div class="info-primary">
      <!-- <ng-container *ngIf="this.fidelityUser.fidelityOption == 1">
        <span *ngIf="this.fidelityUser.bonusType == 1">Complete {{ this.fidelityUser.fidelityCount }} pedidos e ganhe um cupom de {{ this.fidelityUser.bonusValue | currency:'BRL' }} de desconto</span>
        <span *ngIf="this.fidelityUser.bonusType == 2">Complete {{ this.fidelityUser.fidelityCount }} pedidos e ganhe um cupom de {{ this.fidelityUser.bonusPercent }}% de desconto</span>
        <span *ngIf="this.fidelityUser.bonusType == 3">Complete {{ this.fidelityUser.fidelityCount }} pedidos e ganhe um cupom de Frete Gratís</span>
      </ng-container>
      <ng-container *ngIf="this.fidelityUser.fidelityOption == 2">
        <span *ngIf="this.fidelityUser.bonusType == 1">Complatando {{ this.fidelityUser.fidelityValue | currency:'BRL' }} em consumo na loja e ganhe um cupom de {{ this.fidelityUser.bonusValue | currency:'BRL' }} de desconto</span>
        <span *ngIf="this.fidelityUser.bonusType == 2">Complatando {{ this.fidelityUser.fidelityValue | currency:'BRL' }} em consumo na loja e ganhe um cupom de {{ this.fidelityUser.bonusPercent }}% de desconto</span>
        <span *ngIf="this.fidelityUser.bonusType == 3">Complatando {{ this.fidelityUser.fidelityValue | currency:'BRL' }} em consumo na loja e ganhe um cupom de Frete Gratís</span>
      </ng-container> -->
      


    </div>

    <div class="box-rules" style="margin-bottom: 20px;">
      <div class="title-rules">
        <span >Regras</span>
      </div>
      <ul class="list-rules">
        <li class="row-rules" *ngIf="this.fidelityUser.fidelityOption == 1">Realizar {{ this.fidelityUser.fidelityCount }} pedidos</li>
        <li class="row-rules" *ngIf="this.fidelityUser.fidelityOption == 2">Utlize {{ this.fidelityUser.fidelityValue | currency: 'BRL' }}</li>
        <li class="row-rules">Válido para pedidos com status concluído.</li>
        <li class="row-rules">Pedidos cancelados não serão contabilizados.</li>
      </ul>
    </div>

    <div class="box-rules" style="margin-bottom: 95px;">
      <div class="title-rules">
        <span>Prêmio</span>
      </div>
      <div class="coupon-icon">
        <img src="./../../../../assets/icons/cupom.png" style="rotate: -41deg; margin-bottom: 10px; margin-top: 7px;"/>
        <div class="discount-information">
          <span *ngIf="this.fidelityUser.bonusPercent != 0.00">{{ this.fidelityUser.bonusPercent }}% de desconto</span>
          <span *ngIf="this.fidelityUser.bonusValue != 0.00">{{ this.fidelityUser.bonusValue | currency: 'BRL' }} de desconto</span>
          <span *ngIf="this.fidelityUser.bonusType == 3">FRETE GRATÍS</span>
        </div>
      </div>
      <span class="discount-premium" *ngIf="this.fidelityUser.fidelityType == 1">O desconto é válido apenas para os produtos:</span>
      <span class="discount-premium" *ngIf="this.fidelityUser.fidelityType == 2">O desconto é aplicado no valor final do pedido.</span>
      <span class="discount-premium" *ngIf="this.fidelityUser.fidelityType == 3">O desconto é aplicado no Frete do seu pedido.</span>
      <!-- <ng-container *ngIf="this.fidelityUser.fidelityType == 1">
        <span class="discount-premium" *ngIf="this.fidelityUser.bonusValue != 0.00">{{ this.fidelityUser.bonusValue | currency:'BRL' }} de desconto nos produtos:</span>
        <span class="discount-premium" *ngIf="this.fidelityUser.bonusPercent != 0.00">{{ this.fidelityUser.bonusPercent }}% de desconto nos produtos:</span>
      </ng-container>
      <ng-container *ngIf="this.fidelityUser.fidelityType == 2">
        <span class="discount-premium" *ngIf="this.fidelityUser.bonusValue != 0.00">{{ this.fidelityUser.bonusValue | currency:'BRL' }} de desconto no seu pedido</span>
        <span class="discount-premium" *ngIf="this.fidelityUser.bonusPercent != 0.00">{{ this.fidelityUser.bonusPercent }}% de desconto no seu pedido</span>
      </ng-container> -->
      <ul class="list-rules" *ngIf="this.fidelityUser.fidelityType == 1">
        <ng-container *ngFor="let product of this.fidelityUser.productsDiscountInfo">
          <li class="row-rules">{{ product.name }}</li>
        </ng-container>
      </ul>
    </div>

    <div class="bottom-menu">
      <button *ngIf="!this.fidelityUser.fidelityApproved" class="disabled">Resgatar</button>
      <button *ngIf="this.fidelityUser.fidelityApproved" (click)="direct()">Resgatar</button>
    </div>
  </div>
</div>


<app-card-loading [openCard]="showLoading" [labelPerson]="'Aguarde...'"></app-card-loading>