<splash class="splash" *ngIf="!stopLoading" id="splash"></splash>
<div class="box-img-error" *ngIf="gif">
  <div class="div-img-error">
  <img  src="assets/logo/logo-pedeaqui.png">
  </div>
</div>

<div class="main" #main >
    <main class="main-container" #mainContainer>
      <router-outlet></router-outlet>
    </main>
</div>




