import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injectable, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheService } from '../../services/Cache/cache.service';
import { CartService } from '../../services/Cart/cart.service';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { Subscription } from 'rxjs';
import { OrderService } from '../../services/Order/order.service';
import { ApiService } from '../../services/API/api.service';
import { EventsService, event } from 'src/app/shared/services/events/events.service';
import { DOCUMENT } from '@angular/common';
import { WebhookService } from '../../services/WeebHook/webhook.service';
import { BottomMenuService } from '../../services/bottom-Service/bottom-menu.service';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';
import { Fidelity } from 'src/app/Interfaces/fidelity';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.css', '../../../../assets/styles/global/_modal.scss']
})
@Injectable({
  providedIn: 'root'
})
export class BottomMenuComponent implements OnInit, AfterViewInit {

  @Input() verification: string = '';
  private webHookSubscription!: Subscription;
  //#region Variaveis de elemento HTML
    @ViewChildren('button') buttons!: QueryList<ElementRef>;
    @ViewChildren('bottomMenu') bottomMenu!: ElementRef;

  //#endregion

  //#region Variaveis Locais

    //Variaveis booleanas
    alterationButtonModalCall = false;
    linkRoute = false;
    openModalCloseStore = false;
    openModalNotPermission = false;
    showButtons = false;
    activeLink = false;
    alterationButtonUrl = false;
    permissionRequest = true;
    vibrationCircle = false;
    activeButtonWaiter = false;
    openModalCallCard = false;
    openModalCallTable = false;
    orderConsumption: boolean = false;
    statusOpen: boolean = false;
    readOnlyState: boolean = false;
    openModalCompany: boolean = false;

    //Variaveis string
    idOrder = ''
    textObservationWaiter: string = '';
    nameCompany!:string;
    typeParam!: typeInterface;
    numberInput: string = '';
    routeComplete: string = '';
    rotaRelativa: string = '';
    
    //Variaveis Numericas
    statusCompany: number = 0;
    temporaryCounter = 0;
    numberTable: number = 0;
    counterCart: number = 0;
    alternationButton: number = 0;
    numberCard: number = 0;
    id_empresa!: number;
    countText: number = 0;

    //Variaveis de Objetos
    company!: Company;
    fidelityGet: boolean = false;
    user!: Cliente;
    fidelityUser!: Fidelity;


    userConfirmation = this.cacheService.getItemLocal('userConfirmation');
  //#endregion

  //#region Inicializadores
    constructor(
      private router: Router,
      private cacheService: CacheService,
      private cdRef: ChangeDetectorRef,
      private route: ActivatedRoute,
      private cartService: CartService,
      private connection: ConnectionService,
      private orderService: OrderService,
      private apiService: ApiService,
      private renderer: Renderer2,
      private toastr: ToastrService,
      private webHookService: WebhookService,
      private bottomService: BottomMenuService,
      public eventService: EventsService,
      @Inject(DOCUMENT) private document: Document,
      ){
        this.bottomService.activeButtonWaiter$.subscribe((data) =>{
          this.activeButtonWaiter = data
        })
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam
          if(this.company){
            this.validationReadOnly();
          }
        })

        this.cartService.idTableCard$.subscribe(data =>{
          if(data)
          {
            this.cacheService.setItemLocal('number_local', data)
            this.numberTable = Number(data);
          }
        })

        this.cartService.counter$.subscribe(newCount => {
          this.counterCart = newCount;
        });

        this.connection.statusCompany$.subscribe((newStatus) =>{
          this.statusCompany = newStatus
          if(this.statusCompany == 0 && this.typeParam.type == 0){
            this.statusOpen = false;
          }
          else if(this.statusCompany == 0 && this.typeParam.type == 1 || this.typeParam.type == 2){
            this.statusOpen = true;
          }
          else if(this.statusCompany == 0 && this.typeParam.type == 3){
            this.statusOpen = false;
          }
          else if(this.statusCompany == 1){
            this.statusOpen = true;
          }
        })

        this.connection.permissionRequestOrder$.subscribe((newPermission) =>{
          this.permissionRequest = newPermission
        })

        this.orderService.idOrder$.subscribe((data) =>{
          this.idOrder = data
        })

        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany
        })

        this.connection.fidelity$.subscribe((data) =>{
          if(data){
            this.fidelityUser = data
            this.fidelityGet = true;
          }
        })

    }

    ngOnInit(){
      this.router.events.subscribe((val) => {
        this.routeComplete = this.router.url;
      });
      this.route.url.subscribe(segments => {
        this.rotaRelativa = segments.join('/');
      });

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {
          if(content.info){
            if(content.info[0].orders[0].id_order == this.idOrder)
            {
              this.bottomService.activeButtonWaiterSubject.next(false)
              this.activeButtonWaiter = false
              this.orderService.counterOrderSubject.next(content.info[0].orders[0].items.length)
            }

          }
          if(content.type == 3){
            this.getOrderConsuption();
          }
          else if(content.type == 1){
            const data = content.data

            if(data.webhooks){
              if(this.idOrder == data.webhooks[0].id_order){
                if(data.webhooks[0].orderStatus == 0 || data.webhooks[0].orderStatus == 5){
                  this.router.navigate(['/Authentication']);
                }
                else if(data.webhooks[0].orderStatus == 20){
                  this.connection.permissionRequestOrderSubject.next(false);
                  this.permissionRequest = false;
                }
                else if(data.webhooks[0].orderStatus == 1){
                  this.connection.permissionRequestOrderSubject.next(true);
                  this.permissionRequest = true;
                }
              }
            }
          }
        },
        (error) => {
          console.error('Erro ao receber mensagem do servidor WebHook:', error);
        }
      );

      if(this.typeParam.type != 0 && this.typeParam.type != 3)
      {
        if(this.typeParam.type == 2)
        {
          this.numberCard = Number(this.typeParam.numberParam)
        }
        else if(this.typeParam.type == 1){

          this.numberTable = Number(this.typeParam.numberParam)
        }
      }

      if(this.nameCompany == undefined)
      {
        this.nameCompany = this.cacheService.getItemLocal('nameCompany')
      }



      // this.cdRef.detectChanges()
      // this.showButton()

      if(this.verification == "")
      {
        if(this.typeParam.numberParam != 0)
        {
          this.verification = this.typeParam.numberParam.toString()
        }
      }

      if(this.router.url.includes('menu'))
      {
        this.linkRoute = true;
      }
      if(this.typeParam.type == 0 || this.typeParam.type == 3)
      {
        this.showButtons = true;
      }

      if(this.router.url.includes('order-details'))
      {
        this.activeLink = true;
      }

      this.connection.user$.subscribe((User) =>{
        if(User)
        {
          this.user = User
          this.userConfirmation = true;
          if(!this.fidelityGet)
          {
            if(this.id_empresa && this.user.id_customer){
              this.connection.getFidelity(this.user.id_customer, this.id_empresa)
            }
          }
        }
        else if(this.cacheService.getItemLocal('user_key'))
        {
          this.connection.getUser();
        }
        else{
          this.userConfirmation = false
        }


      })

      if(this.typeParam.type == 1 || this.typeParam.type == 2)
      {
        this.alternationButton = 2

      }
      else if(this.typeParam.type == 3)
      {
        this.alternationButton = 1
      }
      else{
        this.alternationButton = 0;
      }

      this.connection.company$.subscribe((newCompany) =>{
        if(newCompany){
          this.company = newCompany
          this.id_empresa = newCompany.company.id_company
          if(!this.fidelityGet)
          {
            if(this.id_empresa && this.user){
              this.connection.getFidelity(this.user.id_customer, this.id_empresa)
            }
          }

          if(!this.orderConsumption && this.id_empresa){
            if(this.typeParam.type == 1 || this.typeParam.type == 2)
            this.getOrderConsuption();
          }
          if(this.company){
            this.validationReadOnly();
          }

        }
      })
      this.counter()
    }

    ngAfterViewInit(){
    }


    ngOnDestroy() {
      this.webHookSubscription.unsubscribe();
    }

  //#endregion

  //#region Funções de estilo (Style)

    isActive(): boolean {
      let active: boolean;
      if(this.rotaRelativa == ''){
        active = true
      }
      else{
        active = false;
      }

      return active

    }

    showModalPermission(){
      this.openModalNotPermission = true;
    }

    //Resgata o numero de produtos que contem no pedido para view consumo
    getOrderConsuption(){
      this.apiService.getOrderConsumption(this.numberCard, Number(this.numberTable), this.id_empresa).subscribe((response) => {
        if(!response.orderEmpty){
          this.orderService.idOrderSubject.next(response.response.orders[0].id_order.toString())
          this.idOrder = response.response.orders[0].id_order.toString()
          this.bottomService.activeButtonWaiterSubject.next(false);
          this.activeButtonWaiter = false;
          this.orderConsumption = true;
        }
        else{
          this.bottomService.activeButtonWaiterSubject.next(true);
          this.activeButtonWaiter = true;
          this.orderConsumption = true;
        }

      },
      (error) =>{
        this.router.navigate(['/Authentication']);
      }
      )
    }

    //Resagata o numero de produtos que tem no carrinho
    counter(){
      if(this.cacheService.getItemLocal('product-cart'))
      {
        const value1 = this.cacheService.getItemLocal('product-cart').length;
        let value2 = 0;
        if(this.cacheService.getItemSession('temporary-counter'))
        {
          value2 = this.cacheService.getItemSession('temporary-counter')
        }

        if(value1 != value2)
        {
          this.vibrationCircle = true;
          this.counterCart = this.cacheService.getItemLocal('product-cart').length
          this.cacheService.setItemSession('temporary-counter', this.counterCart)
          setTimeout(() => {
            this.vibrationCircle = false;
          }, 2000);
        }
        else{
          this.vibrationCircle = false;
          this.counterCart = this.cacheService.getItemLocal('product-cart').length
          this.cacheService.setItemSession('temporary-counter', this.counterCart)
        }

      }
      else{
        this.counterCart = 0
      }
      this.cdRef.detectChanges()
    }

    //Ativa o botão que foi clicado mudando sua cor
    activeClass(){
      this.buttons.forEach((element: ElementRef) => {
        element.nativeElement.classList.remove('active');

        element.nativeElement.addEventListener('click', () => {
            element.nativeElement.classList.add('active');
        });
      });
    }

    //Desabilita scroll da pagina
    disableScroll(){
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
    }

    //Abilita scroll da pagina
    enableScroll(){
      this.renderer.removeStyle(this.document.body, 'overflow');
    }


    validationReadOnly(){
      //-------------------------
      //TYPE INTERFACE
      // 0 = Delivery
      // 1 - Mesa
      // 2 - Comanda
      // 3 - Balcão
      //-----------------------
      if(this.company.configs.readOnly == 0){
        if(this.typeParam.type == 0){
          if(this.company.configs.deliveryReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
  
        }
        else if(this.typeParam.type == 1){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 2){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 3){
          if(this.company.configs.counterReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
      }
      else{
        this.readOnlyState = true;
      }
    }

    openModalCard(){
      this.openModalCallCard = true;
    }

    //#endregion

  //#region Funções de lógica (Logic)
    redirectModal(){
      this.openModalCloseStore = false;
      this.disableScroll()
      this.openModalCompany = true;
      // this.menu.openModalCompany();
    }

    receivedEvent(event: Event){
      const inputElement = event?.target as HTMLInputElement;

      if (inputElement.value.length <= 48) {
        this.textObservationWaiter = inputElement.value;
        this.countText = this.textObservationWaiter.length;
      } else {
        inputElement.value = this.textObservationWaiter;
      }
    }

    callWaiter(observation: string, inputValue: string){
      let evento: event;

      if(inputValue != '0')
      {
        this.cacheService.setItemLocal('number_local', inputValue)
        // this.tableNumberSelected = inputValue;
        this.cartService.idTableCardSubject.next(inputValue)
        // this.valorInputModal = inputValue;
        this.numberTable = Number(inputValue)
        this.alterationButtonModalCall = false;

        evento = {
          eventType: 1,
          id_company: this.id_empresa.toString(),
          id_order: '',
          tableNumber: Number(inputValue),
          eventObservation: observation
        }
      }
      else{
        evento = {
          eventType: 1,
          id_company: this.id_empresa.toString(),
          id_order: '',
          tableNumber: Number(this.numberTable),
          eventObservation: observation
        }
      }
      this.textObservationWaiter = '';
      this.countText = 0;
      this.openModalCallCard = false;
      this.openModalCallTable = false;
      this.apiService.events(evento).subscribe((response) =>{
        // console.log(response)
        this.toastr.success('Aguarde até que o garçom venha até a sua mesa', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 5000, progressBar: true });
      })
    }
  
  //#endregion














}
