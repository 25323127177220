  <div class="modal" [ngClass]="{'show': openModalTwo}" *ngIf="this.company">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="justify-content: space-between;">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Sobre o estabelecimento</h1>
          <div class="d-flex align-items-center icon-modal" (click)="this.close()">
            <button type="button" class="btn-close"></button>
          </div>
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="description-company">
            <div class="header-company">
              <div style="display: flex; justify-content: center; align-items: center;">
                <div class="div-company__logo" style="width: 70px; height: 70px; border-radius: 40px;background-size: cover; border: 1px solid #e7e7e7;" [ngStyle]="{'background-image': 'url('+ this.company.company.logo +')'}"></div>
              </div>
              <div class="header-company__name">{{ this.company.company.companyName }}</div>
            </div>
            <div class="description-first">
              <div >
                <div style="border-top: 1px solid #cfcfcf; padding-top: 10px;">
                  <span style="padding-left: 10px;">Endereço</span>
                </div>
              </div>
              <div class="description-body">
                <div>
                  <ng-container *ngIf="this.addressCompany">
                    <span>{{this.addressCompany.street + ", " + this.addressCompany.number + " - " + this.addressCompany.district + ", " +  this.addressCompany.city + " - " + this.addressCompany.state + ", " + this.addressCompany.country + ", " + this.addressCompany.postalCode}}</span>
                  </ng-container>
                  <a [href]="this.addressUrl">
                    <div class="icon-container">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="description-first">
              <div>
                <div style="padding-top: 10px;">
                  <span style="padding-left: 10px;">Formas de pagamento</span>
                </div>
              </div>
              <div class="description-body" *ngIf="this.company">
                <ng-container *ngFor="let form of this.company.company.paymentForms">
                  <div style="gap: 10px;">
                    <div class="card__choice__option__icon" [ngSwitch]="form.icon">
                      <i *ngSwitchCase="1"><img src="../../../assets/icons//method-payment/dinheiro.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="2"><img src="../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="3"><img src="../../../assets/icons/method-payment/generic-card-2.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="4"><img src="../../../assets/icons/method-payment/mastercard-card.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="5"><img src="../../../assets/icons/method-payment/visa-card.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="6"><img src="../../../assets/icons/method-payment/pix.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="7"><img src="../../../assets/icons/method-payment/american-express.png"  style="width: 25px;"/></i>
                      <i *ngSwitchCase="8"><img src="../../../assets/icons/method-payment/vr-card.png"  style="width: 25px;"/></i>
                      <i *ngSwitchDefault><img src="../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                  </div>
                    <span>{{ form.formName }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="description-first">
              <div>
                <div style="padding-top: 10px;">
                  <span style="padding-left: 10px;">Horário de atendimento</span>
                </div>
              </div>
              <div class="description-body" style="border-bottom: none;">
                <div class="opening" *ngIf="this.opening">
                  <ng-container *ngIf="this.opening.sunday == 1">
                    <span>Domingo: {{ opening.opensunday }} às {{ opening.closesunday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.monday == 1">
                    <span>Segunda-feira: {{ opening.openmonday }} às {{ opening.closemonday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.tuesday == 1">
                    <span>Terça-feira: {{ opening.opentuesday }} às {{ opening.closetuesday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.wednesday == 1">
                    <span>Quarta-feira: {{ opening.openwednesday }} às {{ opening.closewednesday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.thursday == 1">
                    <span>Quinta-feira: {{ opening.openthursday }} às {{ opening.closethursday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.friday == 1">
                    <span>Sexta-feira: {{ opening.openfriday }} às {{ opening.closefriday }}</span>
                  </ng-container>
                  <ng-container *ngIf="this.opening.saturday == 1">
                    <span>Sabado: {{ opening.opensaturday }} às {{ opening.closesaturday }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>