import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-copy-pix',
  templateUrl: './input-copy-pix.component.html',
  styleUrl: './input-copy-pix.component.css'
})
export class InputCopyPixComponent implements OnInit{
  @Input() valueKey: string | undefined = '';
  @Input() dateTimeTransation: string = '';
  counterFinalizado = false;

  ngOnInit(): void {
  }


  finalizationCounter(event: any){
    this.counterFinalizado = event
  }
}
