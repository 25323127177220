import { Component, EventEmitter, Output } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { CacheService } from '../../services/Cache/cache.service';
import { EventsService } from '../../services/events/events.service';
import { ApiService } from '../../services/API/api.service';

interface Observation {
  textObservation: string;
  id_observation: number;
  id_companyFK: string;
}
interface TypeObservation {
  name: string;
  value: number;
}
@Component({
  selector: 'app-observation-card',
  templateUrl: './observation-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './observation-card.component.css']
})


export class ObservationCardComponent {
  step = true;
  company!: Company;
  selectedOption: { checked: boolean, value: number, name: string } = { checked: false, value: 0, name: ''};
  @Output() optionSelected: EventEmitter<{ checked: boolean, value: number, name: string }> = new EventEmitter<{ checked: boolean, value: number, name: string }>();

  typesObservation: TypeObservation[] = [];
  typeSelected = {name: '', value: 0};

  constructor(private connection: ConnectionService, private apiService: ApiService){}

  ngOnInit(): void {
      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        this.getObservations();
      })
  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  onCheckboxChange(data: { checked: boolean, value: any }, name: string) {

    this.selectedOption = { checked: data.checked, value: data.value, name: name};

    this.optionSelected.emit({ checked: data.checked, value: data.value, name: name});

    this.typeSelected = {name: name, value: data.value};

  }

  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0
    this.optionSelected.emit({ checked: false, value: 0, name: '' });
    this.typeSelected = {name: '', value: 0};
  }

  getObservations(){
    this.apiService.getObservation(this.company.company.id_company).subscribe(
      (data) =>{
        data.observations.forEach((observation: Observation) => {
          const body = {
            name: observation.textObservation,
            value: observation.id_observation
          }
          this.typesObservation.push(body)
        });
        console.log(this.typesObservation)

      },
      (error) =>{
        console.log(error);
      }
    )
  }


}
