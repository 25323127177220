import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { ApiService } from '../../services/API/api.service';
import { AddressCompany, Open } from 'src/app/Interfaces/company';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-info-company',
  templateUrl: './info-company.component.html',
  styleUrls: ['./info-company.component.css', '../../../../assets/styles/global/_modal.scss']
})
export class InfoCompanyComponent implements OnInit, AfterViewInit{
  @Input() openModalTwo: boolean = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  nameCompany: string = '';
  company!: Company;
  opening!: Open;
  addressCompany!: AddressCompany;
  addressUrl: string = '';

  constructor(
    private connection: ConnectionService,
    private apiService: ApiService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,){
    this.connection.nameCompany$.subscribe((newNameCompany: string) => {
      this.nameCompany = newNameCompany;
    })
    this.connection.company$.subscribe((data) =>{
      this.company = data
      if(this.company){
        this.opening = this.company.company.open
        this.addressCompany = this.company.company.address;
        this.formattedAddress();
      }
    })
  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
      
  }


  formattedAddress(){
    if(this.company){
      const address = this.company.company.address;
      const addressCompletted = `${address.street}, ${address.number} - ${address.district}, ${address.city} - ${address.state}, ${address.country}`;

      const addressCodificate = encodeURIComponent(addressCompletted);
      this.addressUrl = `https://www.google.com/maps/search/?api=1&query=${addressCodificate}`
    }

  }

  close(){
    this.openModalTwo = false;
    this.closeModal.emit(true)
  }
}
