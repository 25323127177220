import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { SplashComponent } from './shared/components/splash/splash.component';
import { LayoutModule } from './shared/components/layout/layout.module';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MaskedUrlPipe } from './shared/pipes/url.pipe';
import { ConnectionService } from './shared/services/Connection/connection.service';
import { UserDataComponent } from './views/user-data/user-data.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { PedidosComponent } from './views/pedidos/pedidos.component';
import { ConsumptionComponent } from './views/consumption/consumption.component';
import {OrderConfirmationComponent } from './views/order-confirmation/order-confirmation.component';
import { DatetimeFormatPipe } from './shared/pipes/datetime-format.pipe';
import { OrderDetailsComponent } from './views/order-details/order-details.component';
import { CartComponent } from './views/cart/cart.component';
import { AuthenticationComponent } from './shared/components/authentication/authentication.component';
import { AddressComponent } from './views/address/address.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SlideComponent } from './shared/components/slide/slide.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar/progress-bar.component';
import { LoadingProcessingOrderComponent } from './shared/components/loading-processing-order/loading-processing-order/loading-processing-order.component';
import { OrderConfirmationCounterComponent } from './views/orderConfirmationCounter/order-confirmation-counter.component';
import { HistoryEventsComponent } from './shared/components/history-events/history-events.component';
import { MenuComponent } from './views/menu/menu.component';
import { SideBarComponent } from './shared/components/side-bar/side-bar.component';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import { TopHeaderComponent } from './shared/components/top-header/top-header.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { EditProductComponent } from './views/edit-product/edit-product.component';
import { notEmojisDirective } from './shared/directives/notEmojis.directive';
import { UserViewComponent } from './views/user-view/user-view.component';
import { FidelityComponent } from './views/fidelity/fidelity.component';
import { TimeFormatDirective } from './shared/directives/timeFormat.directive';
import { CouponsComponent } from './views/coupons/coupons.component';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';


const components = [
  AppComponent,
  MaskedUrlPipe,
  CheckboxComponent,
  UserDataComponent,
  ConsumptionComponent,
  OrderConfirmationComponent,
  DatetimeFormatPipe,
  PedidosComponent,
  OrderDetailsComponent,
  CartComponent,
  AuthenticationComponent,
  MenuComponent,
  SideBarComponent,
  AddressComponent,
  SlideComponent,
  ProgressBarComponent,
  LoadingProcessingOrderComponent,
  OrderConfirmationCounterComponent,
  HistoryEventsComponent,
  TopBarComponent,
  ProductDetailComponent,
  TopHeaderComponent,
  EditProductComponent,
  notEmojisDirective,
  UserViewComponent,
  FidelityComponent,
  TimeFormatDirective,
  CouponsComponent,
]

@NgModule({
  declarations: components,
  imports: [
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientJsonpModule,
    NgScrollbarModule,
    SlickCarouselModule,
    MatRadioModule,
    RouterModule
  ],
  exports: components,
  providers: [
    ConnectionService,
    SplashComponent,
    CurrencyPipe,
    DatePipe,
    MenuComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
