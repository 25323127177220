<div class="timer" *ngIf="this.visualization == 0 && !this.disable">
    <div class="icon">
        <i class="fa-regular fa-clock"></i>
    </div>
    <div class="time">
        <span>{{ this.timeFrom }}min - </span><span>{{ this.timeTo }}min</span>
    </div>
</div>

<div class="timer-visualizationOne" *ngIf="this.visualization == 1 && !this.disable">
    <div>
        <span style="color: #a1a1a1; font-size: 14px;" *ngIf="this.typeOrder == 5">Previsão de entrega</span>
        <span style="color: #a1a1a1; font-size: 14px;" *ngIf="this.typeOrder == 3 || this.typeOrder == 2">Previsão de retirada</span>
    </div>
    <div class="time-large">
        <span>{{ this.timeFrom }} - </span><span>{{ this.timeTo }}</span>
    </div>
</div>
