import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConnectionService } from '../Connection/connection.service';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {
  private socket!: WebSocket;
  private subject = new Subject<any>();
  private readonly socketUrl = 'wss://webhook.pedeaqui.app';
  //private readonly socketUrl = 'ws://192.168.1.200:4203';
  private idCompany = 0
  private reconnectInterval = 5000;

  constructor(private connection: ConnectionService){
    this.connection.company$.subscribe((data) =>{
      if(data)
      {
        this.idCompany = data.company.id_company
      }
    })
    this.connect();
  }

  private connect() {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.socket = new WebSocket(this.socketUrl);
    }

    this.socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);

      if (this.idCompany === data.data.company.id_company) {
        this.subject.next(data);
      }
    });

    this.socket.addEventListener('open', (event) => {
      console.log('Conexão WebSocket aberta');
    });

    this.socket.addEventListener('close', (event) => {
      console.log('Conexão WebSocket fechada');
      console.log('Reconectando...')
      setTimeout(() => this.connect(), this.reconnectInterval);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('Erro na conexão WebSocket:', event);
      this.subject.error(event);
    });
  }

  public getUpdates(): Observable<any> {
    return this.subject.asObservable();
  }

  public closeConnection() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }
  }
}
