import { DatePipe, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CouponUser } from 'src/app/Interfaces/coupon';
import { Cliente } from 'src/app/Interfaces/customer';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { ConnectionService } from 'src/app/shared/services/Connection/connection.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrl: './coupons.component.css'
})
export class CouponsComponent implements OnInit, AfterViewInit{

  nameCompany: string = '';
  user!: Cliente;
  datesFormatted: string[] = [];
  viewSelected: number = 1;
  couponsUserValid: CouponUser[] = [];
  couponsUserInvalid: CouponUser[] = [];
  notCouponsUserValid: boolean = false;
  notCouponsUserInvalid: boolean = false;
  openRuleCoupon: boolean = false;
  rulesCouponSelected!: CouponUser;
  showTopMenu: boolean = false;
  
  constructor(
    private router: Router,
    private connection: ConnectionService,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private renderer: Renderer2,
    private authLayout: AuthLayoutComponent,
    @Inject(DOCUMENT) private document: Document,
  ){
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    this.connection.user$.subscribe((data) =>{
      if(data){
        this.user = data;
      }
    })
  }

  ngOnInit(): void {
    this.filterCoupons();
  }

  ngAfterViewInit(): void {
      this.checkScreenSize()
  }

  //Desabilita o scroll da pagina
  disableScroll(){
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden')
  }

  //Abilita o scroll da pagina
  enableScroll(){
    this.renderer.removeStyle(this.document.body, 'overflow');
  }


  //Troca de view entre disponiveis e indisponiveis
  changeView(view: number){
    this.viewSelected = view
  }


  goBack(){
    this.router.navigate([this.nameCompany, 'user']);
  }

  @HostListener('window:resize', [])
  onWindowResize(event: any) {
    this.checkScreenSize();
  }

  //Verifica o tamanho da tela, esconde o Menu superior se necessario
  checkScreenSize(){
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.authLayout.checkScreenSize();
  }


  //Valida os cupons do usuario
  filterCoupons(){
    this.couponsUserInvalid = [];
    this.couponsUserValid = [];
    if(this.user.coupons && this.user.coupons.length != 0){
      this.user.coupons.forEach(coupon =>{
        const transformedDate = this.datePipe.transform(coupon.couponExpires, 'dd-MM-yyyy HH:mm');

        if (transformedDate) {
          coupon.expiresFormatted = transformedDate;
        }

        if(!coupon.expired){

          this.couponsUserValid.push(coupon)
        }
        else{
          this.couponsUserInvalid.push(coupon)
        }
      })

      this.calcDaysMissing();

      if(this.couponsUserValid.length > 0){
        this.notCouponsUserValid = false;
      }
      else{
        this.notCouponsUserValid = true;
      }
      if(this.couponsUserInvalid.length > 0){
        this.notCouponsUserInvalid = false;
      }
      else{
        this.notCouponsUserInvalid = true;
      }
    }
    else{
      this.notCouponsUserValid = true;
      this.notCouponsUserInvalid = true;
    }
  }

  //Calcula quantos dias falta para expirar o cupom
  calcDaysMissing(){
    this.apiService.getHours().subscribe(
      (data) =>{
        this.couponsUserValid.forEach(coupon => {
          const dataAlvo = new Date(coupon.couponExpires);

          const today = new Date(data.date)

          const diffTime = dataAlvo.getTime() - today.getTime();

          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          this.datesFormatted.push(diffDays.toString())
  
        });
      },
      (error) =>{}
    )
  }

  //Abre o canva de regras do cupon
  openRulesCoupon(coupon: CouponUser){
    this.openRuleCoupon = true;
    this.rulesCouponSelected = coupon;
    this.disableScroll()
  }
}
