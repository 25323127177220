<div class="card__order" *ngIf="!this.selectedOption.checked">
    <div class="card__title" [ngClass]="{'active-title': this.step}">
      <div style="font-size: 14px;">Selecione seu local</div>
    </div>
    <div class="card__choice__body" [ngClass]="{'active-body': this.step}" >
        <div class="card__choice__option" *ngFor="let type of this.typesObservation">
            <div class="card__choice__option__title">
            <div style="font-size: 14px;">{{ type.name }}</div>
            </div>
            <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: type.value }" (checkboxChange)="onCheckboxChange($event, type.name)"></checkbox-radius>
        </div>
    </div>
</div>

<div class="card__order" *ngIf="this.selectedOption.checked">
    <div class="card__title">
      <div style="font-size: 14px;">Seu local</div>
    </div>
    <div class="card__selected__body">
        <ng-container>
            <div class="card__selected__option">
                <div class="card__selected__option__title">
                  <div style="font-size: 14px;">{{ this.typeSelected.name }}</div>
                </div>
                <div class="card__selected__option__button" *ngIf="this.company">
                  <button (click)="changeButton()">Alterar</button>
                </div>
              </div>
              <app-time-await [typeOrder]="2" [visualization]="0"></app-time-await>
        </ng-container>
    </div>
</div>

