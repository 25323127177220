<div class="box-key" *ngIf="this.valueKey">
    <div class="counter" *ngIf="!this.counterFinalizado">
        <i class="fa-solid fa-stopwatch" style="font-size: 16px; color: var(--primary-color);"></i><app-counter-timing [dateTimeTransaction]="this.dateTimeTransation" [fontSize]="'16px'" (finalizionCounter)="this.finalizationCounter($event)"></app-counter-timing>
    </div>
    <span class="text-information">Copie o código Pix, e faça o pagamento no seu banco, antes do tempo expirar.</span>
    <div class="input-pix">
        <input [value]="this.valueKey" disabled/>
    </div>
    <app-copy-button [valueInput]="this.valueKey"></app-copy-button>
</div>


