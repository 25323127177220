<app-top-menu-two *ngIf="this.showTopMenu"></app-top-menu-two>
<div class="main" [ngClass]="{'responsive': this.showTopMenu}" *ngIf="this.userConfirmation" style="max-width: 800px; margin: 0 auto;">
  <div class="navegation-header-logeed" *ngIf="this.showTopMenu">
    <div class="navegation-header__icon-logeed" (click)="goback()">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
    <div class="navegation-header__tittle-logeed">
      <h3>Perfil</h3>
    </div>
  </div>
    <div class="header" *ngIf="this.user">
        <span style="font-size: 17px; color: #5d5d5d;">Bem vindo</span>
        <span class="user-name">{{ this.user.name }}</span>
    </div>
    <div class="box-option">
      <ng-container *ngIf="this.fidelityGet && this.fidelityUser">
        <div class="option" (click)="navigateFidelity()" *ngIf="this.fidelityUser.fidelity == true">
          <i class="fa-solid fa-handshake"></i>
          <div class="content-option">
            <span class="title-option">Fidelidade</span> 
            <span class="description-option">Minhas fidelidades</span>
          </div>
          
          <div class="option-nav">
            <ng-container *ngIf="this.fidelityUser.fidelityApproved">
              <div class="circle-red-new">
                <span>1</span>
              </div>
            </ng-container>
            <div class="arrow-option">
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </div>

        </div>
      </ng-container>

      <div class="option" (click)="navigateCoupons()">
        <i class="fa-solid fa-ticket"></i>
        <div class="content-option">
          <span class="title-option">Cupons</span>
          <span class="description-option">Meus cupons de desconto</span> 
        </div>
        <div class="option-nav">
          <!-- <div class="button-short" style="right: 36px;">
            <button>Novo</button>
          </div> -->
          <div class="arrow-option">
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>

      <div class="option">
        <i class="fa-solid fa-location-dot disabled-option"></i>
        <div class="content-option disabled-option">
          <span class="title-option">Endereços</span> 
          <span class="description-option">Meus endereços de entrega</span>
        </div>
        <div class="option-nav">
          <div class="button-short">
            <button>Em breve</button>
          </div>
          <!-- <div class="arrow-option">
            <i class="fa-solid fa-chevron-right"></i>
          </div> -->
        </div>
      </div>

      <div class="option" (click)="this.openLogOut = true">
          <i class="fa-solid fa-right-to-bracket"></i>
          <div class="content-option">
            <span class="title-option">Sair</span>
            <span class="description-option">Sair do seu perfil</span>  
          </div>
          <div class="option-nav">
            <div class="arrow-option">
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </div>
      </div>
        
    </div>
</div>



<div *ngIf="!this.userConfirmation" [ngClass]="{'responsive': this.showTopMenu}">
    <div class="container" style="overflow: auto;">
      <div class="navegation-header d-flex align-items-center">
        <div class="box-title">
          <h3 style="margin-bottom: 0px;">Entre ou Cadastre-se</h3>
        </div>
      </div>
      <form (ngSubmit)="submitForm()">
        <div style="padding-bottom: 90px;" class="fist-box">
          <div class="user-data">
            <div class="d-flex px-2 flex-column box-data">
              <span>Número de celular</span>
              <input class="form-control" style="padding-right: 35px;" type="text" placeholder="(_ _) _ _ _ _ _ - _ _ _ _" (keyup)="validatePhoneNumber()" [(ngModel)]="this.phoneNumber" name="phoneNumber" [ngClass]="{'is-valid': this.corretFormatPhoneNumber == true, 'is-invalid': this.corretFormatPhoneNumber == false}" maxlength="14" #inputPhone required>
            </div>
            <div class="d-flex px-2 flex-column box-data">
              <span>Nome e sobrenome</span>
              <input class="form-control" style="padding-right: 35px;" type="text" [(ngModel)]="name" name="name" placeholder="Nome e sobrenome" [ngClass]="{'is-valid': this.lengthInputName == true, 'is-invalid': this.lengthInputName == false}" (input)="onInputChangeName($event)" required="true" #inputName>
            </div>
            <div class="d-flex px-2 flex-column box-data">
              <span *ngIf="!this.requiredBirth">Data de nascimento (Opcional)</span>
              <span *ngIf="this.requiredBirth">Data de nascimento</span>
              <input class="form-control" style="padding-right: 35px;" type="text" [(ngModel)]="birthData" (keyup)="validateBirthDate()" name="birthData" placeholder="DD/MM/AAAA" [ngClass]="{'is-valid': this.correctFormatBirth == true, 'is-invalid': this.correctFormatBirth == false}" required="true" maxlength="10" #inputBirth>
            </div>
          </div>
        </div>
        <div class="bottom-navegation">
          <div class="bottom-navegation__box">
            <button type="submit" class="buttonConfirmation not-disabled" *ngIf="this.alterationButton" [ngClass]="{'not-disabled': lengthInputName && lengthInputPhone && lengthInputBirth}">
                Entrar
            </button>
            <button type="submit" class="buttonConfirmation not-disabled" *ngIf="!this.alterationButton" [ngClass]="{'not-disabled': lengthInputName && lengthInputPhone && lengthInputBirth}">
                Cadastrar
            </button>
          </div>
        </div>
    </form>
    </div>
    
</div>

<div class="modal" [ngClass]="{'show': openLogOut}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" style="color: var(--primary-color); font-size: 17px;">Sair</h1>
      </div>
      <div class="modal-body">
        <span style="font-size: 16px;">Deseja sair da sua conta?</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn modal-btn-negative" (click)="this.openLogOut = false">Fechar</button>
        <button type="button" class="btn modal-btn-positive" (click)="this.disconnectUser()">Sair</button>
      </div>
    </div>
  </div>
</div>


<app-bottom-menu></app-bottom-menu>
