
<app-top-menu-two *ngIf="showTopMenu"></app-top-menu-two>
<div style="height: 100vh; max-width: 800px; margin: auto;" [ngClass]="{'response': this.showTopMenu}">

  <div class="box-header">
    <div class="container-header">
      <div class="navegation-header d-flex align-items-center">
          <div class="box-icon" (click)="goBack()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
          <div class="box-title">
            <h3 style="margin-bottom: 0px;">Cupons</h3>
          </div>
      </div>
    </div>
    <div class="nav-coupons">
      <div class="nav-item" (click)="changeView(1)">
        <span class="nav-name" [ngClass]="{'active': this.viewSelected == 1}">Disponíveis</span>
        <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 1}"></i>
      </div>
      <div class="nav-item" (click)="changeView(2)">
        <span class="nav-name" [ngClass]="{'active': this.viewSelected == 2}">Indisponíveis</span>
        <i class="nav-bar" [ngClass]="{'active': this.viewSelected == 2}"></i>
      </div>
    </div>
  </div>




<!--Coupons Valids-->
<div class="box-coupons" *ngIf="this.viewSelected == 1" [ngClass]="{'not-coupon': this.notCouponsUserValid}">
  <div class="div-not-cupons" *ngIf="this.notCouponsUserValid">
    <img src="assets/icons/cray.png" />
    <span>Você ainda não possui cupons</span>
  </div>
  <ng-container *ngFor="let coupon of this.couponsUserValid; let i = index">
    <div class="coupon-option">
      <div class="box-content">
        <div class="content">
          <div class="coupon-icon">
            <img src="./../../../../assets/icons/cupom.png" />
          </div>
          <div class="coupon">
            <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1">
              <span>Fidelidade</span>
            </div>
            <ng-container *ngIf="coupon.couponValuePercent != 0">
              <div class="coupon-title" >
                {{ coupon.couponValuePercent }}% de desconto
              </div>
            </ng-container>
  
            <ng-container *ngIf="coupon.couponValueCash != '0.00'">
              <div class="coupon-title">
              {{ coupon.couponValueCash | currency: 'BRL' }} de desconto
              </div>
            </ng-container>
  
            <ng-container *ngIf="coupon.couponType == 3">
              <div class="coupon-title">
                FRETE GRÁTIS
              </div>
            </ng-container>
  
            <div class="coupon-content">
              <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
              </div>
              <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                Sem pedido mínimo
              </div>
              <div class="coupon-expires" *ngIf="this.datesFormatted[i] != '1'" style="color: var(--primary-color);">
                Expira em {{ this.datesFormatted[i] }} dias
              </div>
              <div class="coupon-expires" *ngIf="this.datesFormatted[i] == '1'"  style="color: var(--primary-color);">
                Expira hoje
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rules-coupon" (click)="openRulesCoupon(coupon)">
        <span>Ver Detalhes</span>
    </div>
    </div>
  </ng-container>

</div>

<!--Coupons Invalids-->
<div class="box-coupons" *ngIf="this.viewSelected == 2" [ngClass]="{'not-coupon': this.notCouponsUserInvalid}">
  <div class="div-not-cupons" *ngIf="this.notCouponsUserInvalid">
    <img src="assets/icons/ghost.png" style="width: 120px;"/>
    <span>Tudo vazio por aqui</span>
  </div>
  <ng-container *ngFor="let coupon of this.couponsUserInvalid; let i = index">
    <div class="coupon-option">
      <div class="box-content invalid">
        <div class="content">
          <div class="coupon-icon">
            <img src="./../../../../assets/icons/cupom-disabled.png"/>
          </div>
          <div class="coupon">
            <div class="flag-fidelity" *ngIf="coupon.couponFidelity == 1" style="background-color: #a7a7a7;">
              <span>Fidelidade</span>
            </div>
            <ng-container *ngIf="coupon.couponValuePercent != 0" >
              <div class="coupon-title disabled">
                {{ coupon.couponValuePercent }}% de desconto
              </div>
            </ng-container>
  
            <ng-container *ngIf="coupon.couponValueCash != '0.00'">
              <div class="coupon-title disabled">
              {{ coupon.couponValueCash | currency: 'BRL' }} de desconto
              </div>
            </ng-container>
  
            <ng-container *ngIf="coupon.couponType == 3">
              <div class="coupon-title disabled">
                FRETE GRÁTIS
              </div>
            </ng-container>
  
            <div class="coupon-content">
              <div class="coupon-rules" *ngIf="coupon.couponMinValue != '0.00'">
                Pedido min {{ coupon.couponMinValue | currency: 'BRL' }}
              </div>
              <div class="coupon-rules" *ngIf="coupon.couponMinValue == '0.00'">
                Sem pedido mínimo
              </div>
              <div class="coupon-expires" style="color: gray;">
                Expirado
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rules-coupon" (click)="openRulesCoupon(coupon)">
        <span>Ver Detalhes</span>
    </div>
    </div>
  </ng-container>

</div>
</div>



<div class="box-off-canva" [ngClass]="{'open': this.openRuleCoupon}" (click)="this.openRuleCoupon = false; this.enableScroll()"></div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" [ngClass]="{'show': this.openRuleCoupon}" style="height: fit-content; z-index: 120;">
  <div class="header-info">
    <div></div>
  </div>
  <div class="offcanvas-body" style="padding-top: 5px" *ngIf="this.rulesCouponSelected">
    <div class="content-offCanva" style="gap: 10px;">
      <span class="title-offCanva">Detalhes do Cupom</span>
      <div class="box-rules">
        <ul class="list-rules">

          <!--Tipo do Desconto-->
          <ng-container *ngIf="this.rulesCouponSelected.couponType == 1">
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValuePercent != 0"><b class="discount-detail">{{ this.rulesCouponSelected.couponValuePercent }}% de desconto</b> no pedido</li>
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValueCash != '0.00'"><b class="discount-detail">{{ this.rulesCouponSelected.couponValueCash | currency:'BRL' }} de desconto</b> pedido</li>
          </ng-container>
          <ng-container *ngIf="this.rulesCouponSelected.couponType == 2">
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValuePercent != 0"><b class="discount-detail">{{ this.rulesCouponSelected.couponValuePercent }}% de desconto</b> em produtos</li>
            <li class="row-rules" *ngIf="this.rulesCouponSelected.couponValueCash != '0.00'"><b class="discount-detail">{{ this.rulesCouponSelected.couponValueCash | currency:'BRL' }} de desconto</b> em produtos</li>
          </ng-container>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponType == 3"><b class="discount-detail">FRETE GRÁTIS</b></li>

          <!--Tipo de utilização-->
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponLimitUse >= 1">Pode ser utilizado {{ this.rulesCouponSelected.couponLimitUse + 1 }} vez</li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponLimitUse == 0">Pode ser utilizado uma vez</li>

          <!--Pedido Minimo-->
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponMinValue != '0.00'">Pedido minímo de <b style="color: var(--primary-color);">{{ this.rulesCouponSelected.couponMinValue | currency:'BRL' }}</b></li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.couponMinValue == '0.00'">Sem pedido mínimo</li>

          <!--Data de expiração-->
          <li class="row-rules" *ngIf="!this.rulesCouponSelected.expired">Expira em <b style="color: var(--primary-color); font-weight: 100;">{{ this.rulesCouponSelected.expiresFormatted }}</b></li>
          <li class="row-rules" *ngIf="this.rulesCouponSelected.expired">Expirou em <b style="color: var(--primary-color); font-weight: 100;">{{ this.rulesCouponSelected.expiresFormatted }}</b></li>
        </ul>
      </div>
    </div>
    <div class="button-offCanva">
      <button (click)="this.openRuleCoupon = false; this.enableScroll()">Fechar</button>
    </div>
  </div>
</div>
