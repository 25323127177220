<!-- <div class="price-total-order" [ngClass]="{'close': this.openCouponView}"> -->
<div class="price-total-order">
    <div class="total">
      <div class="summary-total">
        <div class="total-value">
          <div class="total-span">Subtotal</div>
          <div class="total-span">{{ this.subTotal | currency:'BRL' }}</div>
        </div>
        <div class="total-value" *ngIf="this.sacola.deliveryFee != 0 || this.deliveryFeeFree">
          <div class="total-span">Taxa de entrega</div>
          <div class="total-span" *ngIf="!this.deliveryFeeFree">{{ this.sacola.deliveryFee | currency:'BRL' }}</div>
          <div class="total-span" style="color: #09a509;" *ngIf="this.deliveryFeeFree">Grátis</div>
        </div>
        <div class="total-value" *ngIf="this.sacola.fee">
          <div style="display: flex; align-items: center">
            <div *ngIf="this.sacola.fee.type == 1">Desconto</div>
            <div *ngIf="this.sacola.fee.type == 2">Taxa adicional</div>
            <div class="button-question" (click)="openInfoFee()">
              <i class="fa-solid fa-question"></i>
            </div>
          </div>
          <div class="total-span" style="color:#09a509" *ngIf="this.sacola.fee.type == 1">-{{ this.sacola.fee.valueFee | currency:'BRL' }}</div>
          <div class="total-span" *ngIf="this.sacola.fee.type == 2">{{ this.sacola.fee.valueFee | currency:'BRL' }}</div>
        </div>
        <div class="total-value" *ngIf="this.sacola.discountValue && this.sacola.couponType != 3">
          <div>Cupom</div>
          <div class="total-span" style="color: #09a509;">-{{ this.sacola.discountValue | currency:'BRL' }}</div>
        </div>
      </div>
      <div class="div-total-value">
        <div class="content-total-value">
          <div>Total</div>
          <div>{{ this.sacola.orderPrice | currency:'BRL' }}</div>
        </div>
      </div>
    </div>
  </div>


  <!--Detalhes de cupom bloqueado-->
<div class="box-off-canva" [ngClass]="{'open': this.openInfo}" (click)="this.openInfo = false; this.scrollService.enableScroll()"></div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" [ngClass]="{'show': this.openInfo}" style="height: fit-content; z-index: 120;" [ngStyle]="{'height': offCanvasHeight + 'px', 'z-index': 120}" #offCanva>
  <div class="header-info"tabindex="-1" [ngClass]="{'show': openInfo}"  (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
    <div></div>
  </div>
  <div class="offcanvas-body" *ngIf="this.sacola.fee">
    <div class="content-offCanva">

      <ng-container *ngIf="this.sacola.fee.type == 1">
        <span class="title-offCanva">Desconto</span>
        <span class="info-offCanva" *ngIf="this.sacola.fee.value">Você ganhou um desconto de <b class="info-values-discount">{{ this.sacola.fee.fee | currency: 'BRL' }}</b> ao pagar com {{ this.sacola.paymentForm[0].paymentName }}.</span>
        <span class="info-offCanva" *ngIf="this.sacola.fee.percent">Você ganhou um desconto de <b class="info-values-discount">{{ this.sacola.fee.fee }}%</b> ao pagar com {{ this.sacola.paymentForm[0].paymentName }}.</span>
      </ng-container>

      <ng-container *ngIf="this.sacola.fee.type == 2">
        <span class="title-offCanva" >Taxa adicional</span>
        <span class="info-offCanva" *ngIf="this.sacola.fee.value">Uma taxa de <b class="info-values-fee">{{ this.sacola.fee.fee | currency: 'BRL' }}</b> é aplicada ao pagar com {{ this.sacola.paymentForm[0].paymentName }}.</span>
        <span class="info-offCanva" *ngIf="this.sacola.fee.percent">Uma taxa de <b class="info-values-fee">{{ this.sacola.fee.fee}}%</b> é aplicada ao pagar com {{ this.sacola.paymentForm[0].paymentName }}.</span>
      </ng-container>
    </div>
    <div class="button-offCanva">
      <button (click)="this.openInfo = false; this.scrollService.enableScroll()">Entendi</button>
    </div>
  </div>
</div>