
<!--Pagamento Online-->
<div class="card__order" *ngIf="!this.selectedOption.checked && this.typeMethodPayment.value == 1">
    <div class="card__title__payment" [ngClass]="{'active-title': this.step}">
        <div style="font-size: 14px;">Selecione a forma de pagamento</div>
        <span style="color: #d1d1d1; font-size: 13px;">Pagamento no app</span>
    </div>
    <div class="card__choice__body" [ngClass]="{'active-body': this.step}">
        <ng-container *ngFor="let form of this.company.company.paymentForms">     
            <div class="card__choice__option" *ngIf="form.onlinePay == 1">
                <div class="card__choice__option__title">
                    <div class="card__choice__option__icon" [ngSwitch]="form.icon">
                        <i *ngSwitchCase="1"><img src="../../../../assets/icons/method-payment/dinheiro.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="2"><img src="../../../../assets/icons/method-payment/generic-card-1.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="3"><img src="../../../../assets/icons/method-payment/generic-card-2.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="4"><img src="../../../../assets/icons/method-payment/mastercard-card.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="5"><img src="../../../../assets/icons/method-payment/visa-card.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="6"><img src="../../../../assets/icons/method-payment/pix.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="7"><img src="../../../../assets/icons/method-payment/american-express.png" style="width: 25px;"/></i>
                        <i *ngSwitchCase="8"><img src="../../../../assets/icons/method-payment/vr-card.png" style="width: 25px;"/></i>
                        <i *ngSwitchDefault><img src="../../../../assets/icons/method-payment/generic-card-1.png" style="width: 25px;"/></i>
                    </div>
                    <div style="font-size: 14px; padding-left: 10px;">{{ form.formName }}</div>
                </div>
                <div class="box-flag">
                    <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: form.formNumber }" (checkboxChange)="onCheckboxChange($event, form)"></checkbox-radius>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!--Pagamento Balcão ou Entrega-->
<div class="card__order" *ngIf="!this.selectedOption.checked && this.typeMethodPayment.value == 0">
    <div class="card__title__payment" [ngClass]="{'active-title': this.step}">
        <div style="font-size: 14px;">Selecione a forma de pagamento</div>
        <span style="color: #d1d1d1; font-size: 13px;" *ngIf="this.methodReceipt.value == 5">Pagamento na entrega</span>
        <span style="color: #d1d1d1; font-size: 13px;" *ngIf="this.methodReceipt.value == 3 || this.methodReceipt.value == 2">Pagamento no balcão</span>
    </div>
    <div class="card__choice__body" [ngClass]="{'active-body': this.step}">
        <ng-container *ngFor="let form of this.company.company.paymentForms">
            <div class="card__choice__option" *ngIf="form.deliveryPay == 1">
                <div class="card__choice__option__box">
                    <div class="card__choice__option__icon" [ngSwitch]="form.icon">
                        <i *ngSwitchCase="1"><img src="../../../../assets/icons/method-payment/dinheiro.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="2"><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="3"><img src="../../../../assets/icons/method-payment/generic-card-2.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="4"><img src="../../../../assets/icons/method-payment/mastercard-card.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="5"><img src="../../../../assets/icons/method-payment/visa-card.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="6"><img src="../../../../assets/icons/method-payment/pix.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="7"><img src="../../../../assets/icons/method-payment/american-express.png"  style="width: 25px;"/></i>
                        <i *ngSwitchCase="8"><img src="../../../../assets/icons/method-payment/vr-card.png" style="width: 25px;"/></i>
                        <i *ngSwitchDefault><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                    </div>
                    <div class="card__choice__option__title">
                        <div style="font-size: 14px; padding-left: 10px;">{{ form.formName }}</div>
                    </div>
                </div>
                <!-- <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: form.formNumber }" (checkboxChange)="onCheckboxChange($event, form.formName, form.id_paymentForm, form.icon)"></checkbox-radius> -->
                <checkbox-radius [checkboxData]="{ checked: selectedOption.checked, value: form.formNumber }" (checkboxChange)="onCheckboxChange($event, form)"></checkbox-radius>
            </div>
        </ng-container>
    </div>
</div>

<!--Pagamento selecionado-->
<div class="card__order" *ngIf="this.selectedOption.checked">
    <div class="card__title__payment">
        <div style="font-size: 14px;">Forma de pagamento</div>
    </div>
    <div class="card__choice__body">
        <ng-container>
            <ng-container >
                <div class="card__choice__option" *ngIf="this.selectedOption.checked">
                    <div class="card__choice__option__box">
                        <div class="card__choice__option__icon" [ngSwitch]="this.selectedOption.form.icon">
                            <i *ngSwitchCase="1"><img src="../../../../assets/icons/method-payment/dinheiro.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="2"><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="3"><img src="../../../../assets/icons/method-payment/generic-card-2.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="4"><img src="../../../../assets/icons/method-payment/mastercard-card.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="5"><img src="../../../../assets/icons/method-payment/visa-card.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="6"><img src="../../../../assets/icons/method-payment/pix.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="7"><img src="../../../../assets/icons/method-payment/american-express.png"  style="width: 25px;"/></i>
                            <i *ngSwitchCase="8"><img src="../../../../assets/icons/method-payment/vr-card.png" style="width: 25px;"/></i>
                            <i *ngSwitchDefault><img src="../../../../assets/icons/method-payment/generic-card-1.png"  style="width: 25px;"/></i>
                        </div>
                        <div class="card__choice__option__title">
                            <div style="font-size: 14px; padding-left: 10px;">{{this.selectedOption.form.formName}}</div>
                        </div>
                    </div>

                    <div class="card__selected__option__button">
                        <button (click)="changeButton()">Alterar</button>
                    </div>
                </div>
            </ng-container>
          </ng-container>
    </div>
</div>